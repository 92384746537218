@media (prefers-color-scheme: dark) {
    body {
        color: #FFF;
        background: #000;
    }
}

@media (prefers-color-scheme: light) {
    body {
        color: #000;
        background: #FFF;
    }
}

body {
    font-family: 'Poppins', sans-serif;
}