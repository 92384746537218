@media (prefers-color-scheme: dark) {
    .social-item {
      &:hover {
        background-color: #000;
      }
    }

    .social-item__image {
          filter: invert(1);
    }
}

@media (prefers-color-scheme: light) {
    .social-item {
      &:hover {
        background-color: #FFF;
      }
    }
}

@property --rotate {
    syntax: "<angle>";
    initial-value: 132deg;
    inherits: false;
  }
    
  :root {
    --card-height: 2em;
    --card-width: 2em;
    --card-border-radius: 100%;
  }
  
  .social-items {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  
  .social-item__image {
    width: 24px;
    height: 24px;
  
    .social-item--x & {
      padding: 3.5px;
    }
  }
  
    .social-item {
      position: relative;
  
      width: 2em;
      height: var(--card-height);
      margin: .2em;
  
      border-radius: var(--card-border-radius);
      font-size: 20px;
  
      justify-content: center;
      align-items: center;
      text-align: center;
      display: flex;
  
      transition: ease-in-out;
      
      cursor: pointer;
  
      &:hover:before, &:hover:after {
        opacity: 1;
      }
    
    // border
    &::before {
      content: "";
  
      position: absolute;
      z-index: -1;
  
      width: 112%;
      height: 110%;
      border-radius: var(--card-border-radius);
      background-image: linear-gradient( var(--rotate) , #EC1E27, #F37522, #F2E900, #04AB4D, #3A6CB4, #704D9E, #BA3F96 );
      
      animation: spin 8s linear infinite;
      opacity: 0;
    }
    
    // blur
    &::after {
      content: "";
  
      position: absolute;
      z-index: -2;
  
      height: 100%;
      width: 100%;
      border-radius: var(--card-border-radius);
  
      filter: blur(calc(var(--card-height) / 6));
      background-image: linear-gradient(var(--rotate), #EC1E27, #F37522, #F2E900, #04AB4D, #3A6CB4, #704D9E, #BA3F96 );
  
      animation: spin 8s linear infinite;
      animation: glow 2.5s infinite alternate;
      opacity: 0;
    }
    
    @keyframes spin {
      0% {
        --rotate: 0deg;
      }
      100% {
        --rotate: 360deg;
      }
    }
  
    @keyframes glow {
      0% {
        transform: scale(0.9);
      }
      100% {
        transform: scale(1.06);
      }
    }
  }

  @media screen and (min-width: 680px) {
    .social-items {
      padding: 1em;
    }
  }