.footer {
    position: fixed;
    bottom: 0;
    width: 100vw;
  
    padding: 1em;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    &__copyright {
      font: 300 14px 'Poppins', sans-serif;
    }
  }